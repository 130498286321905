// @flow
import React, { PureComponent, type Node } from 'react'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import isAfterDate from 'date-fns/is_after'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import subMinutes from 'date-fns/sub_minutes'

// Styling
import { colors } from '../styles/colors'

// Containers
import DateContainer from '../containers/Date'
import PontveerContainer from '../containers/Pontveer'

import type { IState as IDateState } from '../containers/Date'
import type {
  IState as IPontveerState,
  IRoutes,
  IRoute,
  ILine
} from '../containers/Pontveer'

type IProps = IDateState & IPontveerState

export class PontveerComponent extends PureComponent<IProps> {
  _renderRoutes(routes: IRoutes): Node {
    let routeData = routes.filter((route: IRoute) =>
      isAfterDate(route.departureDate, this.props.currentDate)
    )

    // when only 1 timeslot is found, predict the new one based on the difference of the 2 provided
    if (routeData.length < 2 && routes.length === 2) {
      const predictedRoute = {
        ...routes[1],
        id: routes[1].id + '- predicted',
        predicted: true
      }
      predictedRoute.departureDate = subMinutes(
        predictedRoute.departureDate,
        differenceInMinutes(routes[0].departureDate, routes[1].departureDate)
      )
      routeData.push(predictedRoute)
    }

    return routeData.map(
      (route: IRoute): Node => (
        <Departure key={route.id} predicted={route.predicted}>
          {`in ${distanceInWordsToNow(route.departureDate, {
            includeSeconds: true
          })}`}
        </Departure>
      )
    )
  }

  _renderLine = (line: ILine): Node => (
    <Line key={line.id} active={line.status === 'active'}>
      <Destination>
        <span>{line.display_name.split(' - ')[0]} to</span>
        {line.destination_name}
      </Destination>
      {line.status === 'active' && this._renderRoutes(line.routes)}
    </Line>
  )

  render() {
    return <Pontveer>{this.props.lines.map(this._renderLine)}</Pontveer>
  }
}

const ConnectedPontveer = () => (
  <Subscribe to={[DateContainer, PontveerContainer]}>
    {(DateContainer, PontveerContainer) => (
      <PontveerComponent
        {...DateContainer.state}
        {...PontveerContainer.state}
      />
    )}
  </Subscribe>
)

const Pontveer = styled.section`
  font-size: 4.5vw;
  line-height: 2em;
`

const Line = styled.div`
  margin-bottom: 2vh;
  margin-left: 3vw;
  ${props => !props.active && `color: rgba(255, 255, 255, 0.5);`}
`

const Destination = styled.div`
  width: 40vw;
  line-height: 1em;

  span {
    display: block;
    font-size: 0.2em;
    line-height: 1em;
  }
`

const Departure = styled.span`
  margin: 0 5px;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: ${colors.white};
  color: ${colors.primary};
  font-size: 0.45em;
  ${props => props.predicted && `opacity: 0.5;`}
`

export default ConnectedPontveer
