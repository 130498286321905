// @flow

import React, { PureComponent } from 'react'
import { Subscribe } from 'unstated'

// Containers
import PontveerContainer, {
  type IFunctions as IPontveerFunctions
} from '../Pontveer'
import WeatherContainer, {
  type IFunctions as IWeatherFunctions
} from '../Weather'

type Props = {
  feeds: Array<IPontveerFunctions | IWeatherFunctions>
}

class StartLoading extends PureComponent<Props> {
  componentDidMount() {
    this.props.feeds.forEach(feed => {
      feed.startListening()
    })
  }
  render() {
    return null
  }
}

const ConnectedStartLoading = () => (
  <Subscribe to={[PontveerContainer, WeatherContainer]}>
    {(PontveerContainer, WeatherContainer) => (
      <StartLoading
        feeds={[PontveerContainer.functions, WeatherContainer.functions]}
      />
    )}
  </Subscribe>
)

export default ConnectedStartLoading
