// @flow
import { Container } from 'unstated'

export type IState = {
  currentDate: Date
}

class DateContainer extends Container<IState> {
  state = {
    currentDate: new Date()
  }

  constructor() {
    super()

    setInterval(
      () =>
        this.setState({
          currentDate: new Date()
        }),
      1000
    )
  }
}

export default DateContainer
