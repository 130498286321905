import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'unstated'
import { Router } from 'react-router'
import createHistory from 'history/createBrowserHistory'

// Utils
import initGa from './utils/googleAnalytics'
import startCheckingForUpdates from './utils/versionChecker'
import StartLoading from './containers/utils/StartLoading'

// Components
import AppElement from './App'
import routes from './routes'

const history = createHistory()

initGa(history)
startCheckingForUpdates()

export const WrappedApp = App => (
  <Provider>
    <StartLoading />
    <Router history={history}>
      <App routes={routes} />
    </Router>
  </Provider>
)

export const init = el => {
  if (el) {
    ReactDOM.render(WrappedApp(AppElement), el)

    if (module.hot) {
      module.hot.accept('./App', () => {
        ReactDOM.render(WrappedApp(require('./App').default), el)
      })
    }
  }
}

init(document.getElementById('root'))
