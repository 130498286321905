// @flow
import React from 'react'
import styled from 'styled-components'
import formatDate from 'date-fns/format'

// Styling
import { colors } from '../styles/colors'

import type { IRainForCast } from '../containers/Weather'

type IProps = {
  rainForcast: IRainForCast
}

const RainGraphComponent = (props: IProps) => {
  const max = props.rainForcast.reduce(
    (rain, { mmph }) => Math.max(rain, mmph),
    4
  )

  return (
    <RainGraph>
      <Bars>
        {props.rainForcast.map(({ timestamp, mmph }) => (
          <Bar key={timestamp} mmph={mmph} max={max} />
        ))}
      </Bars>
      <Labels>
        {props.rainForcast.map(({ timestamp }) => (
          <Label key={timestamp}>{formatDate(timestamp, 'HH:mm')}</Label>
        ))}
      </Labels>
    </RainGraph>
  )
}

const RainGraph = styled.div`
  /* RainGraph Component */
`

const Bars = styled.div`
  align-items: flex-end;
  display: flex;
  height: 30vh;
`

const Bar = styled.div.attrs(({ mmph, max }) => ({
  style: {
    height: `${(mmph / max) * 30}vh`
  }
}))`
  box-sizing: border-box;
  flex-grow: 1;
  border-top: 2px solid ${colors.white};
  background-color: rgba(255, 255, 255, 0.3);
`

const Labels = styled.div`
  align-items: flex-end;
  display: flex;
`

const Label = styled.div`
  flex-grow: 1;
  font-size: 0.5em;
  text-align: center;
  overflow: hidden;
`

export default RainGraphComponent
