// @flow
import ReactGA from 'react-ga'
import { isClient } from './getRenderPlatform'
import type { Location, RouterHistory } from 'react-router'

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
const ANONYMIZE_DATA = process.env.REACT_APP_ANONYMIZE_GOOGLE_ANALYTICS
const PROD = process.env.NODE_ENV === 'production'

const trackPageView = (location: Location) => {
  ReactGA.pageview(location.pathname)
}

const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: string,
  nonInteraction?: boolean
) => {
  const data = {
    category,
    action,
    label,
    value,
    nonInteraction
  }

  ReactGA.event(data)
}

const initGa = (history: RouterHistory) => {
  if (isClient && !window.ga && GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
      debug: !PROD,
      titleCase: false
    })
    ReactGA.set({ anonymizeIp: ANONYMIZE_DATA })
    trackPageView(history.location)
    history.listen(trackPageView)
  }
}

export { trackPageView, trackEvent }
export default initGa
