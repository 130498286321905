// @flow
import React, { PureComponent } from 'react'
import { Subscribe } from 'unstated'
import styled from 'styled-components'

// Containers
import DateContainer from '../containers/Date'
import WeatherContainer from '../containers/Weather'

// Components
import RainGraph from './RainGraph'

import type { IState as IDateState } from '../containers/Date'
import type { IState as IWeatherState } from '../containers/Weather'

type IProps = IDateState & IWeatherState

export class WeatherComponent extends PureComponent<IProps> {
  render() {
    return (
      <Weather>
        {this.props.weatherdescription} {this.props.temperature}° (
        {this.props.feeltemperature}°)
        {this.props.rainForcast && (
          <RainGraph rainForcast={this.props.rainForcast} />
        )}
      </Weather>
    )
  }
}

const ConnectedWeather = () => (
  <Subscribe to={[DateContainer, WeatherContainer]}>
    {(DateContainer, WeatherContainer) => (
      <WeatherComponent {...DateContainer.state} {...WeatherContainer.state} />
    )}
  </Subscribe>
)

const Weather = styled.section`
  width: 40vw;
  font-size: 1vw;
  line-height: 2em;
`

export default ConnectedWeather
