// @flow
import { Container } from 'unstated'
import axios from 'axios'

export type IRoute = {
  id: string,
  day: number,
  expected_departure_time: string,
  departureDate: Date,
  predicted?: boolean
}

export type IRoutes = Array<IRoute>

export type ILine = {
  id: string,
  status: string,
  destination_name: string,
  display_name: string,
  routes: IRoutes
}

export type ILines = Array<ILine>

export type IState = {
  lastFetch: Date,
  lines: ILines
}

export type IFunctions = {
  startListening: () => void,
  stopListening: () => void
}

const API = process.env.REACT_APP_API_URL || ''
const FETCH_INTERVAL = 3 * 60 * 1000

const createDateFromRoute = (route: IRoute) => {
  const currentDate = new Date()
  const dateParts = route.expected_departure_time
    .split(':')
    .map(part => parseInt(part))

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (route.day > currentDate.getDay() ? 1 : 0),
    dateParts[0],
    dateParts[1],
    dateParts[2]
  )
}

class PontveerContainer extends Container<IState> {
  state = {
    lastFetch: new Date(),
    lines: []
  }
  timer: IntervalID | null = null

  fetchPontveerData = async () => {
    this.setState({
      lastFetch: new Date()
    })

    console.info('[pontveer] Fetching new data')
    try {
      const result = await axios(`${API}/pontveer`)
      const lines = result.data.map(line => ({
        ...line,
        routes: [
          ...line.routes.map(route => ({
            ...route,
            departureDate: createDateFromRoute(route)
          }))
        ]
      }))

      this.setState({
        lastFetch: new Date(),
        lines
      })
    } catch (err) {
      console.error(err)
    }
  }

  startListening = () => {
    if (!this.timer) {
      this.timer = setInterval(this.fetchPontveerData, FETCH_INTERVAL)
      console.info('[pontveer] Start Listening')
      this.fetchPontveerData()
    }
  }

  stopListening = () => {
    if (this.timer) {
      clearInterval(this.timer)
      console.info('[pontveer] Stop Listening')
    }
  }

  functions: IFunctions = {
    startListening: this.startListening,
    stopListening: this.stopListening
  }
}

export default PontveerContainer
