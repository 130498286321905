// @flow
import React from 'react'
import styled from 'styled-components'

// Components
import Pontveer from '../Pontveer'
import Weather from '../Weather'

const HomeComponent = () => (
  <Home>
    <Pontveer />
    <Weather />
  </Home>
)

const Home = styled.div`
  justify-content: space-evenly;
  display: flex;
  width: 100%;
  height: 100%;
`

export default HomeComponent
