// @flow
import React from 'react'
import styled from 'styled-components'

// Components
import LogoElement from './Logo'
import Clock from './Clock'

const HeaderComponent = () => (
  <Header>
    <Logo />
    <Clock />
  </Header>
)

const Header = styled.header`
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  margin: 5vh 0;
`

const Logo = styled(LogoElement)`
  width: 20vw;
`

export default HeaderComponent
