// @flow
import { Container } from 'unstated'
import axios from 'axios'

export type ITimeslot = {
  timestamp: string,
  rain: number,
  mmph: number
}

export type IRainForCast = Array<ITimeslot>

export type IState = {
  rainForcast: IRainForCast,
  weatherdescription: string,
  temperature: number,
  feeltemperature: number
}

export type IFunctions = {
  startListening: () => void,
  stopListening: () => void
}

const API = process.env.REACT_APP_API_URL || ''
const FETCH_INTERVAL = 1 * 60 * 60 * 1000

class WeatherContainer extends Container<IState> {
  state = {}
  timer: IntervalID | null = null

  fetchWeatherData = async () => {
    console.info('[weather] Fetching new data')
    try {
      const result = await axios(`${API}/weather`)
      const weather = result.data

      this.setState(weather)
    } catch (err) {
      console.error(err)
    }
  }

  startListening = () => {
    if (!this.timer) {
      this.timer = setInterval(this.fetchWeatherData, FETCH_INTERVAL)
      console.info('[weather] Start Listening')
      this.fetchWeatherData()
    }
  }

  stopListening = () => {
    if (this.timer) {
      clearInterval(this.timer)
      console.info('[weather] Stop Listening')
    }
  }

  functions: IFunctions = {
    startListening: this.startListening,
    stopListening: this.stopListening
  }
}

export default WeatherContainer
