// @flow

// Global stylesheet
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

// Styling
import { fontFaces } from './fonts'
import { colors } from './colors'
import { textStyles } from './textStyles'

export const styles = `
  ${styledNormalize}
  ${fontFaces}

  html,
  body {
    width: 100%;
    height: 100%;
    ${textStyles.general};
    background-color: ${colors.primary};
    color: ${colors.white};
    overflow: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  a {
    color: ${colors.white};
  }
`

const GlobalStyle = createGlobalStyle`
  ${styles}
`

export default GlobalStyle
