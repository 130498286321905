// @flow
import { fonts } from './fonts'
import { mqFrom } from './mediaQueries'

// Text styles
const textStyles = {
  general: `
    font-family: ${fonts.primary};
    font-size: 18px;
    font-style: normal;
    line-height: 32px;

    ${mqFrom.M`
      font-size: 20px;
      line-height: 36px;
    `};
  `
}

export { textStyles }
