// @flow
import axios from 'axios'
import { isClient } from './getRenderPlatform'

const UPDATE_TIMEOUT: number = 1000 * 60 * 15 // 15 mins
const VERSION_URL: string = process.env.REACT_APP_VERSION_URL || '/version.json'

let currentVersionHash: null | string = null

const checkForUpdate = async () => {
  let newVersionHash = null

  try {
    const result = await axios(`${VERSION_URL}`)
    newVersionHash = result.data.commit
  } catch {
    console.info('[versionChecker] No version file found.')
  }

  if (newVersionHash !== null) {
    if (currentVersionHash !== null) {
      if (currentVersionHash !== newVersionHash) {
        // new version available
        console.info('[versionChecker] New version found, refreshing app...')
        window.location.reload(true)
      } else {
        console.info('[versionChecker] No new version found')
      }
    } else {
      console.info(
        '[versionChecker] Init versions set, starting listening for new versions...'
      )
    }

    currentVersionHash = newVersionHash
  }

  if (window.checkForUpdateTimer) {
    // remove duplicate timeouts
    clearTimeout(window.checkForUpdateTimer)
  }

  // reset timeout
  window.checkForUpdateTimer = setTimeout(checkForUpdate, UPDATE_TIMEOUT)
}

const init = () => {
  if (isClient) {
    checkForUpdate()
    window.checkForUpdateTimer = setTimeout(checkForUpdate, UPDATE_TIMEOUT)
  }
}

export default init
