// @flow
import React from 'react'
import { Subscribe } from 'unstated'
import styled from 'styled-components'
import formatDate from 'date-fns/format'

// Styling
import { colors } from '../styles/colors'

// Containers
import DateContainer from '../containers/Date'

type IProps = {
  currentDate: Date
}

export const ClockComponent = ({ currentDate }: IProps) => (
  <Clock>
    <Digits>{formatDate(currentDate, 'HH')}</Digits>:
    <Digits>{formatDate(currentDate, 'mm')}</Digits>:
    <Digits>{formatDate(currentDate, 'ss')}</Digits>
  </Clock>
)

const ConnectedClock = () => (
  <Subscribe to={[DateContainer]}>
    {DateContainer => <ClockComponent {...DateContainer.state} />}
  </Subscribe>
)

const Clock = styled.div`
  font-size: 4.5vw;
  line-height: 1em;
`

const Digits = styled.span`
  display: inline-block;
  width: 1.3em;
  padding: 8px 10px 16px;
  background-color: ${colors.white};
  color: ${colors.primary};
  text-align: center;
`

export default ConnectedClock
